import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import './AuthPage.css';
import logo from '../../assets/OFFICIAL_LOGO_1.png'
// import {jwtDecode} from 'jwt-decode';
const baseURL = window.location.origin.includes('localhost') ? window.location.origin.replace(/:\d+$/, `:${4000}`) : 'https://api.lrrr.ac'


const Login = ({ switchToRegister }) => {
    const { login } = useContext(AuthContext); // Access the login function from context
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            console.log(baseURL);
            const response = await fetch(`${baseURL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Login successful:', data);
                const token = data.token;
                // const decoded = jwtDecode(token);
                // console.log(decoded.userGroup); // Access the UserGroup value
                login(username, token); // This will store the token in local storage and set auth state
                navigate('/home'); // Redirect to home page
            } else {
                const errorData = await response.json();
                setError(errorData.message);
            }
        } catch (error) {
            console.error('Error:', error);
            setError('An error occurred. Please try again.');
        }
    };

    return (
        <div className="centered-outside-container">
            <div className="centered-inside-container">
                <img src={logo} alt="official logo"/>
                <h1>Facilities Ticket System</h1>
                <hr /> <br />
                <h2>Login</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>Username:</label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Password:</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    {error && <p>{error}</p>}
                    <button type="submit">Login</button>
                </form>
                <p>Don't have an account? <span className="link" onClick={switchToRegister}>Register</span></p>
            </div>
        </div>
    );
};

const Register = ({ switchToLogin }) => {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Email format validation
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(email)) {
            setError("Please enter a valid email address");
            return;
        }

        if (password !== confirmPassword) {
            setError("Passwords do not match");
            return;
        }

        try {
            const response = await fetch(`${baseURL}/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, username, password }),
            });

            if (response.ok) {
                const data = await response.json();
                console.log('Registration successful:', data);
                switchToLogin();
            } else {
                const errorData = await response.json();
                setError(errorData.message);
            }
        } catch (error) {
            console.error('Error:', error);
            setError('An error occurred. Please try again.');
        }
    };

    return (
        <div className="centered-outside-container">
            <div className="centered-inside-container">
                <img src={logo} alt="official logo"/>
                <h1>Facilities Ticket System</h1>
                <hr /> <br />
                <h2>Register</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>Email:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Username:</label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Password:</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Confirm Password:</label>
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </div>
                    {error && <p>{error}</p>}
                    <button type="submit">Register</button>
                </form>
                <p>Already have an account? <span className="link" onClick={switchToLogin}>Login</span></p>
            </div>
        </div>
    );
};

const AuthPage = () => {
    const [isLogin, setIsLogin] = useState(true);

    const switchToRegister = () => setIsLogin(false);
    const switchToLogin = () => setIsLogin(true);

    return (
        <div>
            {isLogin ? (
                <Login switchToRegister={switchToRegister} />
            ) : (
                <Register switchToLogin={switchToLogin} />
            )}
        </div>
    );
};

export default AuthPage;
