import React, { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const ProtectedRoute = () => {
    const location = useLocation();
    const { isAuthenticated, loading } = useContext(AuthContext);

    if (loading) {
        return <div>Loading...</div>; // Or a spinner component
    }

    if (isAuthenticated) {
        if (location.pathname === '/' || location.pathname === '/authPage'){
            return <Navigate to="/home" />
        } else {
            return <Outlet />
        }
    } else {
        return  <Navigate to="/authPage" />;
    }
    
    // return isAuthenticated ? <Outlet /> : <Navigate to="/authPage" />;
};

export default ProtectedRoute;
