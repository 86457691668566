import React, { useState } from 'react';
import './CloseTicketModal.css'

function CloseTicketModal({ isOpen, onClose, onSubmit, ticketId }) {
    const [closingNotes, setClosingNotes] = useState('');
    const [closingImage, setClosingImage] = useState(null);

    const handleImageChange = (event) => {
        setClosingImage(event.target.files[0]);
    };

    const handleSubmit = () => {
        onSubmit(ticketId, closingNotes, closingImage);
        setClosingNotes('');
        setClosingImage(null);
        onClose(); // Close the modal
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <button className="modal-close" onClick={onClose}>×</button>
                <h2>Close Ticket</h2>
                <textarea
                    className="modal-notes"
                    placeholder="Enter closing notes"
                    value={closingNotes}
                    onChange={(e) => setClosingNotes(e.target.value)}
                />
                <input 
                    className="modal-image-input"
                    type="file" 
                    name="closingImage"  // This should match the name expected by Multer
                    onChange={handleImageChange} 
                />
                <div className="modal-buttons">
                    <button onClick={handleSubmit} className="modal-submit-button">Submit</button>
                    <button onClick={onClose} className="modal-cancel-button">Cancel</button>
                </div>
            </div>
        </div>
    );
}

export default CloseTicketModal;
