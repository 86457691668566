import React, { useState } from 'react';
import './TicketsTable.css'; // Import your CSS file
import ImageModal from '../ImageModal/ImageModal'; // Import the ImageModal component
const baseURL = window.location.origin.includes('localhost') ? window.location.origin.replace(/:\d+$/, `:${4000}`) : 'https://api.lrrr.ac'

function TicketsTable({ tickets, onCloseTicket }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImageSrc, setModalImageSrc] = useState('');

    const openModal = (src) => {
        setModalImageSrc(src);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalImageSrc('');
    };

    if (tickets.length === 0) {
        return <p className="no-tickets">No tickets available.</p>;
    }

    // Get the headers dynamically from the keys of the first object
    const headers = Object.keys(tickets[0]);

    return (
        <>
            <table className="tickets-table">
                <thead>
                    <tr className="table-header">
                        {headers.map(header => (
                            <th key={header} className="table-header-cell">
                                {header.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tickets.map(ticket => (
                        <tr key={ticket.Id} className="table-row">
                            {headers.map(header => (
                                <td key={header} className="table-cell">
                                    {header === 'ImagePath' || header === 'ClosingImagePath'  ? (
                                        ticket[header] ? (
                                            <button
                                                onClick={() => openModal(`${baseURL}/${ticket[header].replace(/\\/g, '/')}`)}
                                                className="view-image-button"
                                            >
                                                View Image
                                            </button>
                                        ) : 'No Image'
                                    ) : (
                                        header.includes('At') ? (
                                            ticket[header] ? new Date(ticket[header]).toLocaleString() : 'N/A'
                                        ) : (
                                            ticket[header] || 'N/A'
                                        )
                                    )}
                                </td>
                            ))}
                            <td>
                                {onCloseTicket ? <button onClick={() => onCloseTicket(ticket.Id)}>Close</button> : null}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {isModalOpen && <ImageModal src={modalImageSrc} onClose={closeModal} />}
        </>
    );
}

export default TicketsTable;
