import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true); // Add a loading state

    useEffect(() => {
        // Check if the user is authenticated on initial load
        const token = localStorage.getItem('authToken');
        if (token) {
            setIsAuthenticated(true);
        }
        setLoading(false); // Set loading to false after checking local storage
    }, []);

    const login = (username, userGroupToken) => {
        setIsAuthenticated(true);
        localStorage.setItem('authToken', 'your-token-here');
        localStorage.setItem('username', username);
        localStorage.setItem('userGroupToken', userGroupToken);
    };

    const logout = () => {
        setIsAuthenticated(false);
        localStorage.removeItem('authToken');
        localStorage.removeItem('userGroupToken');
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, loading, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
