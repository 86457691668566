import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../Header/Header";
import TicketsTable from '../TicketsTable/TicketsTable';
import CloseTicketModal from '../CloseTicketModal/CloseTicketModal';
const baseURL = window.location.origin.includes('localhost') ? window.location.origin.replace(/:\d+$/, `:${4000}`) : 'https://api.lrrr.ac'


function OpenTickets() {
    const [tickets, setTickets] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTicketId, setSelectedTicketId] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAdminData = async () => {
            try {
                // Get the token from localStorage
                const token = localStorage.getItem('userGroupToken');

                // Make the fetch request to the admin-protected route
                const response = await fetch(`${baseURL}/admin/dashboard`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}` // Send the token in the Authorization header
                    }
                });

                // Check if the response is okay
                if (response.ok) {
                    const data = await response.json();
                    console.log('Admin data:', data);
                } else {
                    // Handle the case where the user is not authorized
                    console.error('Failed to fetch admin data:', response.statusText);
                    navigate("/authPage");
                }
            } catch (error) {
                console.error('Error fetching admin data:', error);
            }
        };

        const fetchOpenTicketData = async () => {
            try {
                // Get the token from localStorage
                const token = localStorage.getItem('userGroupToken');

                // Make the fetch request to the admin-protected route
                const response = await fetch(`${baseURL}/admin/tickets/open`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}` // Send the token in the Authorization header
                    }
                });

                // Check if the response is okay
                if (response.ok) {
                    const data = await response.json();
                    console.log('Open ticket data:', data);
                    setTickets(data);
                } else {
                    // Handle the case where the user is not authorized
                    console.error('Failed to fetch admin data:', response.statusText);
                    navigate("/authPage");
                }
            } catch (error) {
                console.error('Error fetching admin data:', error);
            }
        };

        fetchAdminData();
        fetchOpenTicketData();
    }, [navigate]);

    const openModal = (ticketId) => {
        setSelectedTicketId(ticketId);
        setIsModalOpen(true);
    };

    const closeTicket = async (ticketId, closingNotes, closingImage) => {
        try {
            const token = localStorage.getItem('userGroupToken');
            const formData = new FormData();
            formData.append('user', localStorage.getItem('username'));
            formData.append('closingNotes', closingNotes);
            formData.append('closingImage', closingImage);

            const response = await fetch(`${baseURL}/admin/tickets/${ticketId}/close`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData
            });

            if (response.ok) {
                // const updatedTickets = tickets.map(ticket =>
                //     ticket.Id === ticketId ? { ...ticket, Status: 'Closed' } : ticket
                // );
                const updatedTickets = tickets.filter(ticket => ticket.Id !== ticketId);

                setTickets(updatedTickets);
            } else {
                console.error('Failed to close ticket:', response.statusText);
            }
        } catch (error) {
            console.error('Error closing ticket:', error);
        }
    };

    return (
        <>
            <Header />
            <h1>Admin Dashboard</h1>
            <TicketsTable tickets={tickets} onCloseTicket={openModal} />
            <CloseTicketModal 
                isOpen={isModalOpen} 
                onClose={() => setIsModalOpen(false)} 
                onSubmit={closeTicket} 
                ticketId={selectedTicketId} 
            />
        </>
    );
}

export default OpenTickets;
