import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

function AdminRoute({ children }) {
    const token = localStorage.getItem('userGroupToken');

    if (!token) {
        return <Navigate to="/authPage" />;
    }

    const decoded = jwtDecode(token);
    if (decoded.userGroup !== 'Admin') {
        return <Navigate to="/home" />;
    }

    return children;
}

export default AdminRoute;