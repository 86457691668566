import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './utils/ProtectedRoute';
import AdminRoute from './utils/AdminRoute';
import AuthPage from './components/AuthPage/AuthPage';
import Home from './components/Home/Home';
import SubmitTicket from './components/SubmitTicket/SubmitTicket';
import OpenTickets from './components/OpenTickets/OpenTickets';
import ClosedTickets from './components/ClosedTickets/ClosedTickets';

function App() {
    return (
        <AuthProvider>
            <Router>
                <Routes>
                    <Route path="/authPage" element={<AuthPage />} />
                    <Route path="/" element={<ProtectedRoute />}>
                        <Route path="/home" element={<Home />} />
                        <Route path="/submitTicket" element={<SubmitTicket />} />
                        <Route
                            path="/admin/openTickets"
                            element={
                                <AdminRoute>
                                    <OpenTickets />
                                </AdminRoute>
                            }
                        />
                        <Route
                            path="/admin/closedTickets"
                            element={
                                <AdminRoute>
                                    <ClosedTickets />
                                </AdminRoute>
                            }
                        />
                    </Route>
                </Routes>
            </Router>
        </AuthProvider>
    );
}

export default App;
