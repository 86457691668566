import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../Header/Header";
import TicketsTable from '../TicketsTable/TicketsTable';
const baseURL = window.location.origin.includes('localhost') ? window.location.origin.replace(/:\d+$/, `:${4000}`) : 'https://api.lrrr.ac'


function ClosedTickets() {
    const [tickets, setTickets] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAdminData = async () => {
            try {
                // Get the token from localStorage
                const token = localStorage.getItem('userGroupToken');

                // Make the fetch request to the admin-protected route
                const response = await fetch(`${baseURL}/admin/dashboard`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}` // Send the token in the Authorization header
                    }
                });

                // Check if the response is okay
                if (response.ok) {
                    const data = await response.json();
                    console.log('Admin data:', data);
                } else {
                    // Handle the case where the user is not authorized
                    console.error('Failed to fetch admin data:', response.statusText);
                    navigate("/authPage");
                }
            } catch (error) {
                console.error('Error fetching admin data:', error);
            }
        };

        const fetchClosedTicketData = async () => {
            try {
                // Get the token from localStorage
                const token = localStorage.getItem('userGroupToken');

                // Make the fetch request to the admin-protected route
                const response = await fetch(`${baseURL}/admin/tickets/closed`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}` // Send the token in the Authorization header
                    }
                });

                // Check if the response is okay
                if (response.ok) {
                    const data = await response.json();
                    console.log('Closed ticket data:', data);
                    setTickets(data);
                } else {
                    // Handle the case where the user is not authorized
                    console.error('Failed to fetch admin data:', response.statusText);
                    navigate("/authPage");
                }
            } catch (error) {
                console.error('Error fetching admin data:', error);
            }
        };

        fetchAdminData();
        fetchClosedTicketData();
    }, [navigate]);

    return (
        <>
            <Header />
            <h1>Admin Dashboard</h1>
            <TicketsTable tickets={tickets} />
        </>
    );
}

export default ClosedTickets;
