import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../Header/Header";
import { jwtDecode } from 'jwt-decode';
import { useEffect } from 'react';

function Home() {
    const navigate = useNavigate();
    const [userGroup, setUserGroup] = useState(null);

    function handleClick() {
        navigate("/submitTicket");
    }

    function handleOpenTicketClick() {
        navigate("/admin/openTickets");
    }

    function handleClosedTicketClick() {
        navigate("/admin/closedTickets");
    }

    useEffect(() => {
        const token = localStorage.getItem('userGroupToken');
        if (token) {
            const decodedToken = jwtDecode(token);
            setUserGroup(decodedToken.userGroup);
        }
    }, []);

    return (
        <>
            <Header />
            <div className="centered-outside-container">
                <div className="centered-inside-container">
                    <button className="rounded-button" onClick={handleClick} >Submit a Ticket</button>
                    <hr/>
                    {userGroup === 'Admin' && (
                        
                        <div>
                            <button className="rounded-button" onClick={handleOpenTicketClick}>
                                View Open Tickets
                            </button>
                            <button className="rounded-button" onClick={handleClosedTicketClick}>
                                View Closed Tickets
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default Home;