import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../Header/Header";
import './SubmitTicket.css'; // Import your CSS file
import LocationStep from './Steps/LocationStep';
import IssueStep from './Steps/IssueStep';
import ImageStep from './Steps/ImageStep';
const baseURL = window.location.origin.includes('localhost') ? window.location.origin.replace(/:\d+$/, `:${4000}`) : 'https://api.lrrr.ac'


function SubmitTicket() {
    const [formState, setFormState] = useState({
        selectedLocation: null,
        locationDescription: '',
        selectedIssue: null,
        issueDescription: '',
        attachedFile: null,
    });

    const [currentStep, setCurrentStep] = useState(1);

    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const { name, value, files } = event.target;
        setFormState(prevState => ({
            ...prevState,
            [name]: files ? files[0] : value
        }));
    };

    const handleButtonClick = (value, type) => {
        setFormState(prevState => ({
            ...prevState,
            [type]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const formData = new FormData();
        formData.append('submittedBy', localStorage.getItem('username'));
        formData.append('selectedLocation', formState.selectedLocation);
        formData.append('locationDescription', formState.locationDescription);
        formData.append('selectedIssue', formState.selectedIssue);
        formData.append('issueDescription', formState.issueDescription);
        if (formState.attachedFile) {
            formData.append('attachedFile', formState.attachedFile);
        }
    
        try {
            const response = await fetch(`${baseURL}/submitTicket`, {
                method: 'POST',
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            } else {
                navigate("/home");
            }
    
            const result = await response.json();
            console.log('Success:', result);
            // Handle success (e.g., show a success message or redirect)
        } catch (error) {
            console.error('Error:', error);
            // Handle error (e.g., show an error message)
        }
    };
    

    const handleNextStep = () => {
        setCurrentStep(prevStep => prevStep + 1);
    };

    const handlePreviousStep = () => {
        setCurrentStep(prevStep => prevStep - 1);
    };

    const isNextEnabled = () => {
        if (currentStep === 1) {
            return formState.selectedLocation && formState.locationDescription.trim() !== '';
        }
        if (currentStep === 2) {
            return formState.selectedIssue && formState.issueDescription.trim() !== '';
        }
        return true; // For step 3, it's optional so always enable "Next"
    };

    const renderStepContent = () => {
        switch (currentStep) {
            case 1:
                return <LocationStep formState={formState} handleButtonClick={handleButtonClick} handleInputChange={handleInputChange} handleNextStep={handleNextStep} isNextEnabled={isNextEnabled} />;
            case 2:
                return <IssueStep formState={formState} handleButtonClick={handleButtonClick} handleInputChange={handleInputChange} handleNextStep={handleNextStep} handlePreviousStep={handlePreviousStep} isNextEnabled={isNextEnabled} />;
            case 3:
                return <ImageStep handleInputChange={handleInputChange} handlePreviousStep={handlePreviousStep} handleSubmit={handleSubmit} />;
            default:
                return null;
        }
    };

    return (
        <>
            <Header />
            <div className="centered-outside-container">
                <div className="centered-inside-container">
                    <p className='submit-ticket-date'>Date: {new Date().toJSON().slice(0, 10).replace(/-/g, '/')}</p>
                    <form className="submit-ticket-grid" onSubmit={handleSubmit}>
                        {renderStepContent()}
                    </form>
                </div>
            </div>
        </>
    );
}

export default SubmitTicket;
