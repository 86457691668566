import React, { useContext } from 'react';
// import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import logo from '../../assets/OFFICIAL_LOGO_1.png'
import './Header.css'

function Header() {
    const { logout } = useContext(AuthContext); // Access the login function from context
    // const navigate = useNavigate();

    return (
        // <header>
        //     <div className="header">
        //         <h1>Playhouse on the Square Facilities Ticket System</h1>
        //         <span className="link" onClick={logout}>Logout</span>
        //     </div>
        // </header>
        <>
            <header>
                <div className="header">
                    <a href="/home" className="logo" style={{ display: 'flex', alignItems: 'center' }}> 
                        <img src={logo} alt="official logo" />
                        <h1 style={{ fontSize: '40px', marginLeft: '10px'}}>Facilities Ticket System</h1>
                    </a>
                    <div className="header-right" >
                        <button className="rounded-button" onClick={logout}>Logout</button>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;