import React from 'react';

function IssueStep({ formState, handleButtonClick, handleInputChange, handleNextStep, handlePreviousStep, isNextEnabled }) {
    const issueOptions = [
        'Electrical',
        'Plumbing',
        'HVAC',
        'Security',
        'Cleaning',
        'Damage',
        'Landscaping',
        'Access',
        'Other'
    ];

    return (
        <>
            <label htmlFor="issue" className="description-label">
                Issue
            </label>
            <div id="issue" className="button-container">
                {issueOptions.map((issue) => (
                    <button
                        key={issue}
                        type="button"
                        className={`submit-ticket-button ${formState.selectedIssue === issue ? 'selected' : ''}`}
                        onClick={() => handleButtonClick(issue, 'selectedIssue')}
                    >
                        {issue}
                    </button>
                ))}
            </div>
            <div className="additional-info-container">
                <label htmlFor="issue-description" className="description-label">
                    Additional Issue Info
                </label>
                <textarea
                    id="issue-description"
                    name="issueDescription"
                    className="description-textarea"
                    placeholder="In your words describe the issue in detail."
                    value={formState.issueDescription}
                    onChange={handleInputChange}
                />
            </div>
            <button
                type="button"
                onClick={handlePreviousStep}
                className="submit-button"
            >
                Back
            </button>
            <button
                type="button"
                onClick={handleNextStep}
                className="submit-button"
                disabled={!isNextEnabled()}
            >
                Next
            </button>
        </>
    );
}

export default IssueStep;
