import React from 'react';
// import './YourModalStyles.css'; // Ensure this is the correct path to your CSS file

function ImageStep({ handleInputChange, handlePreviousStep, handleSubmit }) {
    return (
        <div className="modal-overlay" >
            <div className="modal-content">
                <label htmlFor="attached-file" className="modal-notes">
                    Attach or Take a Picture (Optional)
                </label>
                <input
                    type="file"
                    id="attached-file"
                    name="attachedFile"
                    accept="image/*"
                    className="modal-image-input"
                    onChange={handleInputChange}
                />
                <div className="modal-buttons">
                    <button
                        type="button"
                        onClick={handlePreviousStep}
                        className="modal-cancel-button"
                    >
                        Back
                    </button>
                    <button
                        type="button"
                        onClick={handleSubmit}
                        className="modal-submit-button"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ImageStep;
