import React from 'react';
import './ImageModal.css'; // Import your CSS file for the modal

function ImageModal({ src, onClose }) {
    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <button className="modal-close" onClick={onClose}>×</button>
                <img src={src} alt="Ticket" className="modal-image" />
            </div>
        </div>
    );
}

export default ImageModal;
