import React from 'react';

function LocationStep({ formState, handleButtonClick, handleInputChange, handleNextStep, isNextEnabled }) {
    const locationOptions = [
        'Playhouse on the Square',
        'Playhouse Office Building',
        'Circuit Playhouse',
        'ARC Housing',
        'Warehouse',
        'Guest House',
        'Varnell',
        'Other'
    ];

    return (
        <>
            <label htmlFor="location" className="description-label">
                Location Info
            </label>
            <div id="location" className="button-container">
                {locationOptions.map((location) => (
                    <button
                        key={location}
                        type="button"
                        className={`submit-ticket-button ${formState.selectedLocation === location ? 'selected' : ''}`}
                        onClick={() => handleButtonClick(location, 'selectedLocation')}
                    >
                        {location}
                    </button>
                ))}
            </div>
            <div className="additional-info-container">
                <label htmlFor="location-description" className="description-label">
                    Additional Location Info
                </label>
                <textarea
                    id="location-description"
                    name="locationDescription"
                    className="description-textarea"
                    placeholder="In your words describe in detail where the issue is? What floor? What's nearby? Etc."
                    value={formState.locationDescription}
                    onChange={handleInputChange}
                />
            </div>
            <button
                type="button"
                onClick={handleNextStep}
                className="submit-button"
                disabled={!isNextEnabled()}
            >
                Next
            </button>
        </>
    );
}

export default LocationStep;
